import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, resolveComponent as _resolveComponent, normalizeStyle as _normalizeStyle, createVNode as _createVNode, createBlock as _createBlock, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-179cacea"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "pin-authentication h-auto" }
const _hoisted_2 = { class: "w-full bg-gray-100 rounded p-2" }
const _hoisted_3 = { class: "mt-4 m-0 mb-1 text-right w-full" }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { class: "text-2xl mt-4 text-center" }
const _hoisted_6 = { class: "text-center" }
const _hoisted_7 = { class: "w-full md:w-1/3 p-1 flex flex-col items-center text-left m-auto" }
const _hoisted_8 = { class: "text-xs" }
const _hoisted_9 = ["placeholder"]
const _hoisted_10 = { class: "flex justify-center items-center mt-4 mb-4 flex-col md:flex-row" }
const _hoisted_11 = { class: "px-8 py-4 text-center" }
const _hoisted_12 = ["innerHTML"]
const _hoisted_13 = ["innerHTML"]
const _hoisted_14 = ["innerHTML"]
const _hoisted_15 = { key: 1 }
const _hoisted_16 = { class: "w-full md:w-1/3 p-1 flex flex-col items-center text-left m-auto" }
const _hoisted_17 = { class: "text-2xl mt-4 text-center" }
const _hoisted_18 = { class: "" }
const _hoisted_19 = ["placeholder"]
const _hoisted_20 = { class: "w-full md:w-1/3 p-1 flex flex-col items-center text-left m-auto" }
const _hoisted_21 = { class: "" }
const _hoisted_22 = ["placeholder"]
const _hoisted_23 = { class: "w-full md:w-1/3 p-1 flex flex-col items-center text-left m-auto" }
const _hoisted_24 = { class: "px-8 py-4" }
const _hoisted_25 = ["innerHTML"]
const _hoisted_26 = ["innerHTML"]
const _hoisted_27 = ["innerHTML"]
const _hoisted_28 = {
  key: 2,
  class: ""
}
const _hoisted_29 = { class: "text-2xl mt-4 text-center" }
const _hoisted_30 = { class: "text-container" }
const _hoisted_31 = { class: "text-left m-1" }
const _hoisted_32 = { class: "unique-container" }
const _hoisted_33 = { class: "id-container" }
const _hoisted_34 = ["placeholder"]
const _hoisted_35 = { class: "phone-email" }
const _hoisted_36 = { class: "send-pin" }
const _hoisted_37 = { class: "pin-container" }
const _hoisted_38 = ["placeholder"]
const _hoisted_39 = { class: "flex justify-center items-center mt-4 mb-4 flex-col md:flex-row buttonSend" }
const _hoisted_40 = { class: "px-4 py-2 text-container" }
const _hoisted_41 = ["innerHTML"]
const _hoisted_42 = ["innerHTML"]
const _hoisted_43 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_sp_button = _resolveComponent("sp-button")!
  const _component_sp_icon = _resolveComponent("sp-icon")!
  const _component_sp_select = _resolveComponent("sp-select")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("p", _hoisted_3, [
        (_ctx.languages.en)
          ? (_openBlock(), _createElementBlock("span", {
              key: 0,
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.changeLocale('en'))),
              class: _normalizeClass(["cursor-pointer mr-2 ml-2", _ctx.language === 'en' ? 'font-semibold' : 'text-gray-400'])
            }, _toDisplayString(_ctx.isMobile ? 'EN' : 'English'), 3))
          : _createCommentVNode("", true),
        (_ctx.languages.es)
          ? (_openBlock(), _createElementBlock("span", {
              key: 1,
              onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.changeLocale('es'))),
              class: _normalizeClass(["cursor-pointer", _ctx.language === 'es' ? 'font-semibold' : 'text-gray-400'])
            }, _toDisplayString(_ctx.isMobile ? 'ES' : 'Español'), 3))
          : _createCommentVNode("", true),
        (_ctx.languages.ru)
          ? (_openBlock(), _createElementBlock("span", {
              key: 2,
              onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.changeLocale('ru'))),
              class: _normalizeClass(["cursor-pointer mr-2 ml-2", _ctx.language === 'ru' ? 'font-semibold' : 'text-gray-400'])
            }, _toDisplayString(_ctx.isMobile ? 'RU' : 'Русский'), 3))
          : _createCommentVNode("", true)
      ]),
      (_ctx.authMode === 'default')
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
            _createElementVNode("h1", _hoisted_5, _toDisplayString(_ctx.pinPageCustomization.title[_ctx.language]), 1),
            _createElementVNode("p", _hoisted_6, _toDisplayString(_ctx.pinPageCustomization.subtitle[_ctx.language]), 1),
            _createElementVNode("div", _hoisted_7, [
              _createElementVNode("span", _hoisted_8, _toDisplayString(_ctx.pinPageCustomization.input.label[_ctx.language]), 1),
              _withDirectives(_createElementVNode("input", {
                "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.pin) = $event)),
                autocomplete: "one-time-code",
                type: "text",
                placeholder: _ctx.pinPageCustomization.input.placeholder[_ctx.language]
              }, null, 8, _hoisted_9), [
                [_vModelText, _ctx.pin]
              ])
            ]),
            _createElementVNode("div", _hoisted_10, [
              _createVNode(_component_sp_button, {
                style: _normalizeStyle('background-color:'+ _ctx.pinPageCustomization.buttons.send.color ),
                onOnClick: _ctx.postSendPin,
                label: _ctx.pinPageCustomization.buttons.send.text[_ctx.language],
                inheritedClasses: ['send-pin'],
                class: "m-2",
                disabled: _ctx.hasEmailOrPhone
              }, null, 8, ["style", "onOnClick", "label", "disabled"]),
              _createVNode(_component_sp_button, {
                class: "m-1",
                style: _normalizeStyle('background-color:'+ _ctx.pinPageCustomization.buttons.enter.color ),
                onOnClick: _ctx.checkPin,
                label: _ctx.pinPageCustomization.buttons.enter.text[_ctx.language],
                disabled: !_ctx.pin
              }, null, 8, ["style", "onOnClick", "label", "disabled"])
            ]),
            _createElementVNode("div", _hoisted_11, [
              (_ctx.language == 'en')
                ? (_openBlock(), _createElementBlock("span", {
                    key: 0,
                    innerHTML: _ctx.pinContentHtml.en,
                    class: "pinContantHtml"
                  }, null, 8, _hoisted_12))
                : _createCommentVNode("", true),
              (_ctx.language == 'es')
                ? (_openBlock(), _createElementBlock("span", {
                    key: 1,
                    innerHTML: _ctx.pinContentHtml.es,
                    class: "pinContantHtml"
                  }, null, 8, _hoisted_13))
                : _createCommentVNode("", true),
              (_ctx.language == 'ru')
                ? (_openBlock(), _createElementBlock("span", {
                    key: 2,
                    innerHTML: _ctx.pinContentHtml.ru,
                    class: "pinContantHtml"
                  }, null, 8, _hoisted_14))
                : _createCommentVNode("", true)
            ])
          ]))
        : _createCommentVNode("", true),
      (_ctx.authMode === 'dni+birthday')
        ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
            _createElementVNode("div", _hoisted_16, [
              _createElementVNode("h1", _hoisted_17, _toDisplayString(_ctx.pinPageCustomization.title[_ctx.language]), 1),
              _createElementVNode("label", _hoisted_18, _toDisplayString(_ctx.pinPageCustomization.DNIInputField[_ctx.language]), 1),
              _withDirectives(_createElementVNode("input", {
                "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.dni) = $event)),
                type: "text",
                placeholder: _ctx.pinPageCustomization.DNIPlaceholderField[_ctx.language],
                style: {"border-radius":"0.5rem","height":"50px"}
              }, null, 8, _hoisted_19), [
                [_vModelText, _ctx.dni]
              ])
            ]),
            _createElementVNode("div", _hoisted_20, [
              _createElementVNode("label", _hoisted_21, _toDisplayString(_ctx.$t('general.birthDate')), 1),
              _withDirectives(_createElementVNode("input", {
                class: "p-2 w-full",
                "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.birthDate) = $event)),
                type: "date",
                placeholder: _ctx.$t('pinUnique.placeholderId'),
                style: {"border-radius":"0.5rem","height":"50px"}
              }, null, 8, _hoisted_22), [
                [_vModelText, _ctx.birthDate]
              ])
            ]),
            _createElementVNode("div", _hoisted_23, [
              _createVNode(_component_sp_button, {
                class: "mt-4",
                style: _normalizeStyle(['background-color:'+ _ctx.pinPageCustomization.buttons.enter.color , {"border-radius":"0.5rem","height":"50px"}]),
                onOnClick: _ctx.postSendDniBirthday,
                label: _ctx.pinPageCustomization.buttons.enter.text[_ctx.language],
                disabled: _ctx.hasDniOrBirthday,
                fullRounded: false,
                size: 'full'
              }, null, 8, ["style", "onOnClick", "label", "disabled"])
            ]),
            _createElementVNode("div", _hoisted_24, [
              (_ctx.language == 'en')
                ? (_openBlock(), _createElementBlock("span", {
                    key: 0,
                    innerHTML: _ctx.pinContentHtml.en,
                    class: "pinContantHtml"
                  }, null, 8, _hoisted_25))
                : _createCommentVNode("", true),
              (_ctx.language == 'es')
                ? (_openBlock(), _createElementBlock("span", {
                    key: 1,
                    innerHTML: _ctx.pinContentHtml.es,
                    class: "pinContantHtml"
                  }, null, 8, _hoisted_26))
                : _createCommentVNode("", true),
              (_ctx.language == 'ru')
                ? (_openBlock(), _createElementBlock("span", {
                    key: 2,
                    innerHTML: _ctx.pinContentHtml.ru,
                    class: "pinContantHtml"
                  }, null, 8, _hoisted_27))
                : _createCommentVNode("", true)
            ])
          ]))
        : _createCommentVNode("", true),
      (_ctx.authMode === 'pin+uniqueId')
        ? (_openBlock(), _createElementBlock("div", _hoisted_28, [
            _createElementVNode("h1", _hoisted_29, _toDisplayString(_ctx.pinPageCustomization.title[_ctx.language]), 1),
            _createElementVNode("div", _hoisted_30, [
              _createElementVNode("p", _hoisted_31, _toDisplayString(_ctx.pinPageCustomization.subtitle[_ctx.language]), 1)
            ]),
            _createElementVNode("div", _hoisted_32, [
              _createElementVNode("div", _hoisted_33, [
                _createElementVNode("label", null, _toDisplayString(_ctx.pinPageCustomization.DNIInputField[_ctx.language]), 1),
                _createElementVNode("div", null, [
                  _withDirectives(_createElementVNode("input", {
                    class: "id-input",
                    "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.identification) = $event)),
                    type: "text",
                    placeholder: _ctx.pinPageCustomization.DNIPlaceholderField[_ctx.language],
                    onKeyup: _cache[7] || (_cache[7] = ($event: any) => ( _ctx.customApi === true ? _ctx.checkDocument(_ctx.identification) : ''))
                  }, null, 40, _hoisted_34), [
                    [_vModelText, _ctx.identification]
                  ]),
                  (_ctx.customApi === true ? _ctx.checkDocument(_ctx.identification) : '')
                    ? (_openBlock(), _createBlock(_component_sp_icon, {
                        key: 0,
                        model: 'check'
                      }))
                    : _createCommentVNode("", true)
                ])
              ]),
              _createElementVNode("div", _hoisted_35, [
                (_ctx.isPhone)
                  ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                      _createElementVNode("label", null, _toDisplayString(_ctx.$t('pinUnique.phone')), 1),
                      _createVNode(_component_sp_select, {
                        items: _ctx.phonesContact,
                        value: _ctx.phone,
                        onSelectedValue: _ctx.changePhone,
                        placeholder: _ctx.$t('pinUnique.selectPhone') ,
                        itemsText: _ctx.dropDownListHeader[_ctx.language]
                      }, null, 8, ["items", "value", "onSelectedValue", "placeholder", "itemsText"])
                    ], 64))
                  : _createCommentVNode("", true),
                (_ctx.isEmail)
                  ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                      _createElementVNode("label", null, _toDisplayString(_ctx.$t('pinUnique.email')), 1),
                      _createVNode(_component_sp_select, {
                        items: _ctx.emailsContact,
                        value: _ctx.email,
                        onSelectedValue: _ctx.changeEmail,
                        disabled:  _ctx.customApi && !_ctx.isValidDocument ? true : false,
                        placeholder: _ctx.$t('pinUnique.selectEmail'),
                        itemsText: _ctx.dropDownListHeader[_ctx.language]
                      }, null, 8, ["items", "value", "onSelectedValue", "disabled", "placeholder", "itemsText"])
                    ], 64))
                  : _createCommentVNode("", true),
                _createElementVNode("div", _hoisted_36, [
                  _createVNode(_component_sp_button, {
                    style: _normalizeStyle('background-color:'+ _ctx.pinPageCustomization.buttons.send.color ),
                    onOnClick: _ctx.postSendPin,
                    label: _ctx.pinPageCustomization.buttons.send.text[_ctx.language],
                    inheritedClasses: ['send-pin'],
                    class: "m-2",
                    disabled: _ctx.hasEmailOrPhone
                  }, null, 8, ["style", "onOnClick", "label", "disabled"])
                ])
              ]),
              _createElementVNode("div", _hoisted_37, [
                _createElementVNode("label", null, _toDisplayString(_ctx.pinPageCustomization.input.label[_ctx.language]), 1),
                _withDirectives(_createElementVNode("input", {
                  class: "input-pin",
                  autocomplete: "one-time-code",
                  "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.pin) = $event)),
                  type: "text",
                  placeholder: _ctx.pinPageCustomization.input.placeholder[_ctx.language]
                }, null, 8, _hoisted_38), [
                  [_vModelText, _ctx.pin]
                ])
              ]),
              _createElementVNode("div", _hoisted_39, [
                _createVNode(_component_sp_button, {
                  class: "m-1",
                  style: _normalizeStyle('background-color:'+ _ctx.pinPageCustomization.buttons.enter.color ),
                  onOnClick: _ctx.checkPin,
                  label: _ctx.pinPageCustomization.buttons.enter.text[_ctx.language],
                  disabled: !_ctx.pin
                }, null, 8, ["style", "onOnClick", "label", "disabled"])
              ])
            ]),
            _createElementVNode("div", _hoisted_40, [
              (_ctx.language == 'en')
                ? (_openBlock(), _createElementBlock("span", {
                    key: 0,
                    innerHTML: _ctx.pinContentHtml.en,
                    class: "pinContantHtml"
                  }, null, 8, _hoisted_41))
                : _createCommentVNode("", true),
              (_ctx.language == 'es')
                ? (_openBlock(), _createElementBlock("span", {
                    key: 1,
                    innerHTML: _ctx.pinContentHtml.es,
                    class: "pinContantHtml"
                  }, null, 8, _hoisted_42))
                : _createCommentVNode("", true),
              (_ctx.language == 'ru')
                ? (_openBlock(), _createElementBlock("span", {
                    key: 2,
                    innerHTML: _ctx.pinContentHtml.ru,
                    class: "pinContantHtml"
                  }, null, 8, _hoisted_43))
                : _createCommentVNode("", true)
            ])
          ]))
        : _createCommentVNode("", true)
    ])
  ]))
}