
import {
  computed,
  defineComponent,
  onMounted,
  onUnmounted,
  reactive,
  ref,
  toRefs,
  watch,
} from "vue";
import SupportPageHeader from "@/components/modules/SupportPageHeader.vue";
import VirtualAvatar from "@/components/modules/VirtualAvatar.vue";
import WelcomeText from "@/components/modules/WelcomeText.vue";
import SaveAsFavourite from "@/components/modules/SaveAsFavourite.vue";
import PaymentPlanProposal from "@/components/modules/PaymentPlanProposal.vue";
import PaymentMethod from "@/components/modules/PaymentMethod.vue";
import DebtConsolidation from "@/components/modules/DebtConsolidation.vue";
import Support from "@/components/modules/Support.vue";
import Contact from "@/components/modules/Contact.vue";
import ActionsHistory from "@/components/modules/ActionsHistory.vue";
import Faqs from "@/components/modules/Faqs.vue";
import WhoIsSirit from "@/components/modules/WhoIsSirit.vue";
import MinimizedVideoChatBot from "@/components/modules/MinimizedVideoChatBot.vue";
import SupportPageFooter from "@/components/modules/SupportPageFooter.vue";
import { useStore } from "vuex";
import axios from "axios";
import PaymentSummary from "@/components/modules/PaymentSummary.vue";
import SpIcon from "@/components/ui/SpIcon.vue";
import Files from "@/components/modules/Files.vue";
import FilesModule from "@/components/modules/Files.vue";
import i18n from "@/main";
import WelcomeTitle from "@/components/modules/WelcomeTitle.vue";
import PaymentModule from "@/components/modules/Payment.vue";
import PinAuthentication from "@/components/PinAuthentication.vue";
import router from "@/router";
import FileInterveners from "@/components/modules/FileInterveners.vue";
import RemovalsModule from "@/components/modules/RemovalsModule.vue";
import LinkPageHeader from "@/components/modules/LinkPageHeader.vue";
import FileSelector from "@/components/modules/FileSelector.vue";
import BottomNavigationBar from "@/components/modules/BottomNavigationBar.vue";
import SpModal from "@/components/ui/SpModal.vue";
import SpButton from "@/components/ui/SpButton.vue";
import CallMeNow from "@/components/modules/CallMeNow.vue";
import * as Routing from "@/router/Routing";
import Whatsapp from "@/components/modules/Whatsapp.vue";

export default defineComponent({
  name: "AxactorView",
  components: {
    BottomNavigationBar,
    SpModal,
    SpButton,
    FileSelector,
    FileInterveners,
    PinAuthentication,
    PaymentModule,
    WelcomeTitle,
    ActionsHistory,
    FilesModule,
    SpIcon,
    SupportPageFooter,
    MinimizedVideoChatBot,
    WhoIsSirit,
    Faqs,
    Contact,
    Support,
    DebtConsolidation,
    SupportPageHeader,
    WelcomeText,
    SaveAsFavourite,
    PaymentPlanProposal,
    CallMeNow,
  },
  props: {
    c: { type: String, default: "", required: false },
    client: { type: String, default: "", required: false },
    s: { type: String, default: "", required: false },
    strategy: { type: String, default: "", required: false },
    a: { type: String, default: "", required: false },
    action: { type: String, default: "", required: false },
    t: { type: String, default: "", required: false },
    template: { type: String, default: "", required: true },
    element: { type: String, default: "", required: true },
    uuid: { type: String, default: "", required: true },
    result: { type: String, default: "", required: false },
  },
  setup(props) {
    const store = useStore();
    const windowSize = ref(window.innerWidth);
    store.commit("user/setIsMobile", window.innerWidth < 420);

    onMounted(() => {
      window.addEventListener("resize", () => {
        store.commit("user/setIsMobile", window.innerWidth < 420);
        windowSize.value = window.innerWidth;
      });

      window.addEventListener("scroll", (event) => {
        if (window.top) changeScroll(event, window.top.scrollY);
      });
    });

    onUnmounted(() => {
      window.removeEventListener("resize", () => {
        windowSize.value = window.innerWidth;
        store.commit("user/setIsMobile", window.innerWidth < 420);
      });
    });

    interface AxactorView {
      showBottomFixedNavbar: boolean;
      currentSection: string;
      showDebtSelector: number;
      loadingAuth: boolean;
      authMode: string;
      actionsHistory: {
        index: number;
        enabled: boolean;

        title: string;
        subtitle: string;
        items: Array<any>;
      };
      callMeNowKey: number;
      whatsAppKey: number;
      contactUs: {
        index: number;
        enabled: boolean;
        title: string;
        description: string;
        termsOfUse: boolean;
        privacyPolicy: boolean;
        cookies: boolean;
        moreInfo: boolean;
        saturday: boolean;
        sunday: boolean;
        hideAddressForm: boolean;
        callMeNow: any;
      };
      currency: string;
      debtor: {
        index: number;
        enabled: boolean;
        debtors: Array<any>;
        columns: {
          documentUniqueId: {
            show: boolean;
          };
          fullName: {
            show: boolean;
          };
          position: {
            show: boolean;
          };
          positionType: {
            show: boolean;
          };
        };
      };
      columns: {
        documentUniqueId: {
          show: boolean;
        };
        fullName: {
          show: boolean;
        };
        position: {
          show: boolean;
        };
        positionType: {
          show: boolean;
        };
      };
      debtAmount: number;
      debtConsolidation: {
        index: number;
        enabled: boolean;
        title: string;
        description: string;
      };
      debtorInfo: any;
      debtOwner: string;
      defaultData: {
        phone: string;
        email: string;
        address: string;
      };
      lastVisited: "";
      languages: Array<any>;
      modulesToShow: Array<any>;
      files: {
        index: number;
        enabled: boolean;
        title: any;
        subtitle: any;
        requestMessage: string;
      };
      fileExternalId: string;
      firstLoading: boolean;
      faqs: {
        index: number;
        enabled: boolean;
        title: string;
        subtitle: string;
        items: Array<any>;
      };
      footer: {
        index: number;
        enabled: boolean;
        allowContactUs: boolean;
        allowWhoIsSirit: boolean;
        cookiesPolicyUrl: string;
        privacyPolicyUrl: string;
        termsOfUsePolicyUrl: string;
      };
      jsonData: any;
      landingPageUuid: string;
      loading: boolean;
      paidDebt: number;
      paymentMethod: string;
      paymentPlan: {
        index: number;
        enabled: true;
        title: string;
        subtitle: string;
        moduleHeader: string;
        minMonths: number;
        maxMonths: number;
        maxFeeToPay: number;
        minFeeToPay: number;
        options: Array<any>;
        allowSelection: boolean;
        removalsLimitDate: string;
        removalsPercentage: number;
        planLimitDays: number;
        paymentPlanTableHtml: any;
        partialPayment: {
          minAmount: number;
        }
        proportionalSlider: boolean
      };
      paymentPlanMethod: {
        index: number;
        enabled: boolean;
        allowBizum: boolean;
        allowPayPal: boolean;
        allowCreditCard: boolean;
        allowVenmo: boolean;
        title: any;
        subtitle: any;
      };
      paymentPlantProposal: string;
      pinAuthenticationIsFinished: boolean;
      primaryColor: string;
      removals: {
        enabled: boolean;
        index: number;
      };
      saveAsFavourite: {
        index: number;
        enabled: boolean;
        icon: string;
        color: string;
        fontSize: string;
        url: string;
        message: string;
      };
      support: {
        index: number;
        enabled: boolean;
        allowVideoConference: boolean;
        videoconferenceUrl: string;
        allowChat: boolean;
        chatUrlEn: string;
        chatUrlEs: string;
        chatUrlRu: string;
        title: any;
        subtitle: any;
        channels: any;
      };
      supportPageHeader: {
        enabled: boolean;
        index: number;
        platformName: {
          enabled: boolean;
          logo: string;
        };
        supportBy: {
          enabled: false;
          companies: Array<any>;
        };
      };
      updatedDate: any;
      uploadDocuments: Array<any>;
      welcomeTitle: {
        enabled: boolean;
        index: number;
        url: string;
        title: {
          enabled: boolean;
          color: string;
          fontSize: string;
          message: string;
        };
        description: {
          enabled: boolean;
          color: string;
          fontSize: string;
          message: string;
        };
      };
      welcomeText: {
        index: number;
        enabled: boolean;
        icon: string;
        fontSize: string;
        userName: string;
        errorLink: string;
        debtAmount: number;
        currency: string;
        taxType: string;
        message: string;
        debtType: string;
        debtContractedWith: string;
        debtDate: string;
        debtorName: string;
        pendingDebt: string;
        otherFiles: Array<any>;
        agent: string;
        procecuted: any;
        fileSelectorShow: boolean
      };
      whoIs: {
        index: number;
        enabled: boolean;
        title: {
          allow: boolean;
          color: string;
          fontSize: string;
          content: {
            en: string;
            es: string;
            ru: string;
          };
        };
        content: {
          allow: boolean;
          color: string;
          fontSize: string;
          content: {
            en: string;
            es: string;
            ru: string;
          };
        };
      };
      pinContentHtml: object;
      isCustomApi: boolean;
      variablesForVerifyPayment: {
        Ds_SignatureVersion: any;
        Ds_MerchantParameters: any;
        Ds_Signature: any;
        deadLineId: any;
        fileId: any;
      };
      isTemplate: boolean;
      isStripe: boolean;
      query: {
        state: any;
        deadline: any;
        landing: any;
        amount: any;
        file: any;
      };
      showPaymentStripeModal: boolean;
      phonesContact: any;
      emailsContact: any;
      sendMethod: string;
      pinPageCustomization: any;
      customApi: boolean;
      authModeNew: string;
      dynamicTemplate: string;
      showPaymentModal: boolean;
      checkPaymentWorldLine: any;
      whatsApp: any
      isSiritStripe: boolean
    }

    const data = reactive<AxactorView>({
      showBottomFixedNavbar: false,
      currentSection: "",
      showDebtSelector: 0,
      authMode: "",
      loadingAuth: false,
      actionsHistory: {
        index: 0,
        enabled: false,
        title: "",
        subtitle: "",
        items: [],
      },
      callMeNowKey: 0,
      whatsAppKey: 0,
      contactUs: {
        index: 0,
        enabled: false,
        title: "",
        description: "",
        termsOfUse: false,
        privacyPolicy: false,
        cookies: false,
        moreInfo: true,
        saturday: true,
        sunday: true,
        hideAddressForm: false,
        callMeNow: {},
      },
      currency: "USD",
      debtAmount: 23392,
      debtConsolidation: {
        index: 0,
        enabled: false,
        title: "",
        description: "",
      },
      debtor: {
        index: 0,
        enabled: true,
        debtors: [],
        columns: {
          documentUniqueId: {
            show: false,
          },
          fullName: {
            show: false,
          },
          position: {
            show: false,
          },
          positionType: {
            show: false,
          },
        },
      },
      columns: {
        documentUniqueId: {
          show: false,
        },
        fullName: {
          show: false,
        },
        position: {
          show: false,
        },
        positionType: {
          show: false,
        },
      },
      debtorInfo: null,
      debtOwner: "",
      defaultData: {
        phone: "",
        email: "",
        address: "",
      },
      files: {
        index: 0,
        enabled: true,
        requestMessage: "",
        title: { en: "", es: "", ru: "" },
        subtitle: { en: "", es: "", ru: "" },
      },
      fileExternalId: store.getters["modules/getFileExternalId"],
      firstLoading: false,
      faqs: {
        index: 0,
        enabled: false,
        title: "",
        subtitle: "",
        items: [],
      },
      footer: {
        index: 0,
        enabled: true,
        allowContactUs: true,
        allowWhoIsSirit: true,
        cookiesPolicyUrl: "",
        privacyPolicyUrl: "",
        termsOfUsePolicyUrl: "",
      },
      lastVisited: "",
      languages: [],
      modulesToShow: [],
      jsonData: {},
      landingPageUuid: "",
      loading: false,
      paidDebt: 0,
      paymentMethod: "",
      paymentPlan: {
        index: 0,
        enabled: true,
        title: "",
        subtitle: "",
        moduleHeader: "",
        minMonths: 0,
        maxMonths: 0,
        maxFeeToPay: 0,
        minFeeToPay: 0,
        options: [],
        allowSelection: true,
        removalsLimitDate: "",
        removalsPercentage: 0,
        planLimitDays: 1,
        paymentPlanTableHtml: null,
        partialPayment: {
          minAmount: 0
        },
        proportionalSlider: false
      },
      paymentPlanMethod: {
        index: 7,
        enabled: true,
        allowBizum: true,
        allowPayPal: true,
        allowCreditCard: true,
        allowVenmo: true,
        title: { es: "", en: "", ru: "" },
        subtitle: { es: "", en: "", ru: "" },
      },
      paymentPlantProposal: "",
      pinAuthenticationIsFinished: false,
      primaryColor: "#EC0000",
      removals: {
        enabled: true,
        index: 0,
      },
      saveAsFavourite: {
        index: 0,
        enabled: false,
        icon: "",
        color: "",
        fontSize: "",
        url: "",
        message: "",
      },
      support: {
        index: 0,
        enabled: true,
        title: { en: "", es: "", ru: "" },
        subtitle: { en: "", es: "", ru: "" },
        channels: {
          video: {},
          chat: {},
          message: {},
        },
        allowVideoConference: true,
        videoconferenceUrl: "",
        allowChat: true,
        chatUrlEn: "",
        chatUrlEs: "",
        chatUrlRu: "",
      },
      supportPageHeader: {
        enabled: false,
        index: 0,
        platformName: {
          enabled: false,
          logo: "",
        },
        supportBy: {
          enabled: false,
          companies: [],
        },
      },
      updatedDate: new Date(),
      uploadDocuments: [],
      welcomeTitle: {
        enabled: false,
        index: 0,
        url: "",
        title: {
          enabled: false,
          color: "",
          fontSize: "6xl",
          message: "",
        },
        description: {
          enabled: false,
          color: "",
          fontSize: "",
          message: "",
        },
      },
      welcomeText: {
        index: 0,
        enabled: false,
        icon: "",
        fontSize: "",
        userName: "",
        errorLink: "",
        debtAmount: 0,
        currency: "",
        taxType: "",
        message: "",
        debtType: "",
        debtContractedWith: "",
        debtDate: "",
        debtorName: "",
        pendingDebt: "",
        otherFiles: [],
        agent: "",
        procecuted: "",
        fileSelectorShow: true
      },
      whoIs: {
        index: 0,
        enabled: false,
        title: {
          allow: true,
          color: "#000000",
          fontSize: "",
          content: {
            en: "",
            es: "",
            ru: "",
          },
        },
        content: {
          allow: false,
          color: "",
          fontSize: "#000000",
          content: {
            en: "",
            es: "",
            ru: "",
          },
        },
      },
      pinContentHtml: {
        en: "",
        es: "",
        ru: "",
      },
      isCustomApi: false,
      variablesForVerifyPayment: {
        Ds_SignatureVersion: "",
        Ds_MerchantParameters: "",
        Ds_Signature: "",
        deadLineId: "",
        fileId: "",
      },
      isTemplate: false,
      isStripe: false,
      query: {
        state: "",
        deadline: "",
        landing: "",
        amount: 0,
        file: "",
      },
      showPaymentStripeModal: false,
      phonesContact: [],
      emailsContact: [],
      sendMethod: "",
      pinPageCustomization: {
        colorId: "",
        colorPin: "",
      },
      customApi: false,
      authModeNew: "",
      dynamicTemplate: "",
      showPaymentModal: false,
      checkPaymentWorldLine: '',
      whatsApp: {},
      isSiritStripe: false,
    });

    const isMobile = computed(() => {
      return store.getters["user/getIsMobile"];
    });

    function goToTemplates(value: string) {
      router.push({ name: value });
    }

    // watch(
    //   () => isMobile.value,
    //   () => {
    //     let id: any = props.uuid
    //     if (data.authModeNew === "noAuth" && isMobile.value) {
    //       router.push({ name: "DebtsView", params: id });
    //     } else if (data.authModeNew !== "noAuth" && isMobile.value) {
    //       router.push({ name: "Authentication", params: id });
    //     }
    //   }
    // );

    function changeScroll(event: any, scrollYPosition: number) {
      let navbarModules = [
        "welcomeText",
        "paymentPlan",
        "files",
        "contactUs",
        "faqs",
      ];
      let result: Array<any> = [];

      data.modulesToShow.forEach(function (module: string) {
        if (navbarModules.includes(module)) {
          let modulePosition: number = -1;
          let moduleHtml = document.getElementById(module);
          if (moduleHtml)
            modulePosition =
              moduleHtml.offsetTop - moduleHtml.offsetHeight / 2.5;

          result.push({
            module: module,
            position: modulePosition,
          });
        }
      });

      let resultModule: string = "";
      result.forEach(function (module: any) {
        if (scrollYPosition >= module.position) resultModule = module.module;
      });

      data.currentSection = resultModule;
    }

    function changeSelectedMethod(selectedMethod: any) {
      data.paymentMethod = selectedMethod.method;
    }

    function changeSelectedPayment(selectedPayment: any) {
      data.paymentPlantProposal = selectedPayment.plan;
    }

    const filesInfo = computed(() => {
      return store.getters["modules/getFilesInfo"];
    });

    function getLandingConfiguration() {
      data.loading = true;
      const params = {
        uuid: props.uuid,
        callbackSuccess: (callbackSuccessData: any) => {
          data.firstLoading = true;
          if (callbackSuccessData.data.debtorInfo !== null) {
            data.debtorInfo = callbackSuccessData.data.debtorInfo;
            store.commit(
              "modules/setDebtorInfo",
              callbackSuccessData.data.debtorInfo
            );
          }
          store.commit(
            "modules/setGeneral",
            callbackSuccessData.data.modules.general
          );

          data.landingPageUuid = callbackSuccessData.data["@id"];
          store.commit("user/setUUID", callbackSuccessData.data["@id"]);
          store.commit(
            "user/setLanguage",
            callbackSuccessData.data.modules.general.defaultLanguage
          );
          store.commit("modules/setModules", callbackSuccessData.data.modules);
          data.isCustomApi = callbackSuccessData.data.modules.general.customApi;
          store.commit(
            "user/setCustomApi",
            callbackSuccessData.data.modules.general.customApi
          );
          data.currency = callbackSuccessData.data.modules.general.currency;

          data.isStripe =
            callbackSuccessData.data.modules.general.paymentConfig && callbackSuccessData.data.modules.general.paymentConfig.platform ===
            "stripe"
              ? true
              : false;
          data.isSiritStripe =
            callbackSuccessData.data.modules.general.paymentConfig &&
            callbackSuccessData.data.modules.general.paymentConfig.platform ===
              "sirit_stripe"
              ? true
              : false;
          if (
            callbackSuccessData.data.modules.general.paymentConfig && callbackSuccessData.data.modules.general.paymentConfig.platform ===
            "stripe"
          ) {
            store.commit(
              "user/setStripePublicKey",
              callbackSuccessData.data.modules.general.paymentConfig.public_key
            );
          }
          store.commit(
            "modules/setContactData",
            callbackSuccessData.data.modules.contact
          );

          store.commit("modules/setFaqs", callbackSuccessData.data.modules.faqs.collection)

          i18n.global.locale =
            callbackSuccessData.data.modules.general.defaultLanguage;
          data.languages =
            callbackSuccessData.data.modules.general.extraLanguages;
          // data.updatedDate = callbackSuccessData.data.updated
          data.jsonData = callbackSuccessData.data.modules;
          data.primaryColor =
            callbackSuccessData.data.modules.general.primaryColor;
          store.commit(
            "user/setPrimaryColor",
            callbackSuccessData.data.modules.general.primaryColor
          );
          data.showBottomFixedNavbar =
            callbackSuccessData.data.modules.general.showBottomFixedNavbar;
          store.commit(
            "modules/setSendMethod",
            callbackSuccessData.data.authMode
          );

          data.isTemplate = callbackSuccessData.data.template;
          store.commit("modules/setIsTemplate", callbackSuccessData.data.template)
          getFilesToAddToPayment();

          let filesInfoResult: Array<any> = [];
          for (let fileKey in callbackSuccessData.data.filesInfo) {
            filesInfoResult.push(callbackSuccessData.data.filesInfo[fileKey]);
          }

          store.commit("modules/setFilesInfo", filesInfoResult);

          if (callbackSuccessData.data.fileUuid !== null)
            store.commit(
              "modules/setFileUuid",
              callbackSuccessData.data.fileUuid
            );
          store.commit(
            "modules/setDefaultFileInfo",
            callbackSuccessData.data.modules.intervenerAndFilesInfo.filesInfo
          );

          if (callbackSuccessData.data.lastVisited !== null)
            data.lastVisited = callbackSuccessData.data.lastVisited;

          data.modulesToShow = [];
          if ("documents" in callbackSuccessData.data) {
            data.uploadDocuments = callbackSuccessData.data.documents;
          }

          data.supportPageHeader =
            callbackSuccessData.data.modules.supportPageHeader;
          if (data.supportPageHeader.enabled)
            data.modulesToShow[
              callbackSuccessData.data.modules.supportPageHeader.index
            ] = "supportPageHeader";
          
          if(callbackSuccessData.data.modules.supportPageHeader && callbackSuccessData.data.modules.supportPageHeader.logo) {
            store.commit(
              "security/setFallbackContactInfoLogo",
              callbackSuccessData.data.modules.supportPageHeader.logo
            );
          }

          data.welcomeTitle = callbackSuccessData.data.modules.welcomeTitle;
          if (data.welcomeTitle.enabled)
            data.modulesToShow[
              callbackSuccessData.data.modules.welcomeTitle.index
            ] = "welcomeTitle";

          data.welcomeText = callbackSuccessData.data.modules.welcomeMessage;
          if (data.welcomeText.enabled)
            data.modulesToShow[
              callbackSuccessData.data.modules.welcomeMessage.index
            ] = "welcomeText";
          if (data.showDebtSelector === 0)
            data.showDebtSelector =
              callbackSuccessData.data.modules.welcomeMessage.index;
          store.commit(
            "modules/setDebtSelector",
            callbackSuccessData.data.modules.welcomeMessage.debtSelector
          );

          data.welcomeText.fileSelectorShow = callbackSuccessData.data.modules.welcomeMessage.debtSelector.show
          
          if (
            "debtorInfo" in callbackSuccessData.data &&
            callbackSuccessData.data.debtorInfo !== null
          ) {
            let firstname: string =
              callbackSuccessData.data.debtorInfo.firstname === null
                ? ""
                : callbackSuccessData.data.debtorInfo.firstname;
            let lastname: string =
              callbackSuccessData.data.debtorInfo.lastname === null
                ? ""
                : callbackSuccessData.data.debtorInfo.lastname;
            data.welcomeText.debtorName = firstname + " " + lastname;
          }

          if (
            "filesInfo" in callbackSuccessData.data &&
            callbackSuccessData.data.filesInfo !== null &&
            callbackSuccessData.data.filesInfo.length > 0
          ) {
            data.welcomeText.debtAmount =
              callbackSuccessData.data.filesInfo[
                store.getters["modules/getFileUuid"]
              ].totalDebt;
            data.welcomeText.pendingDebt =
              callbackSuccessData.data.filesInfo[
                store.getters["modules/getFileUuid"]
              ].pendingDebt;
            data.welcomeText.otherFiles =
              callbackSuccessData.data.filesInfo[
                store.getters["modules/getFileUuid"]
              ].otherFiles;
            data.welcomeText.debtDate =
              callbackSuccessData.data.filesInfo[
                store.getters["modules/getFileUuid"]
              ].debtStartDate;
            data.welcomeText.debtType =
              callbackSuccessData.data.filesInfo[
                store.getters["modules/getFileUuid"]
              ].debtType;
            data.welcomeText.agent =
              callbackSuccessData.data.filesInfo[
                store.getters["modules/getFileUuid"]
              ].agent;
            data.welcomeText.procecuted =
              callbackSuccessData.data.filesInfo[
                store.getters["modules/getFileUuid"]
              ].procecuted;
            data.paidDebt =
              callbackSuccessData.data.filesInfo[
                store.getters["modules/getFileUuid"]
              ].paidDebt;
            if (
              callbackSuccessData.data.filesInfo[
                store.getters["modules/getFileUuid"]
              ].transferor !== null
            )
              data.debtOwner =
                callbackSuccessData.data.filesInfo[
                  store.getters["modules/getFileUuid"]
                ].transferor;
          }

          data.saveAsFavourite = callbackSuccessData.data.modules.bookmark;
          if (data.saveAsFavourite.enabled)
            data.modulesToShow[
              callbackSuccessData.data.modules.bookmark.index
            ] = "saveAsFavourite";
          if (data.showDebtSelector === 0)
            data.showDebtSelector =
              callbackSuccessData.data.modules.welcomeMessage.index;

          data.paymentPlan = callbackSuccessData.data.modules.paymentPlan;
          data.paymentPlan.paymentPlanTableHtml =
            callbackSuccessData.data.modules.paymentPlan.paymentPlanTableHtml;
          store.commit(
            "modules/setPaymentPlanDataModule",
            callbackSuccessData.data.modules.paymentPlan
          );
          store.commit(
            "modules/setCard",
            callbackSuccessData.data.modules.paymentPlan.card
          );
          if (data.paymentPlan.enabled)
            data.modulesToShow[
              callbackSuccessData.data.modules.paymentPlan.index
            ] = "paymentPlan";
          if (data.showDebtSelector === 0)
            data.showDebtSelector =
              callbackSuccessData.data.modules.welcomeMessage.index;

          data.paymentPlanMethod =
            callbackSuccessData.data.modules.paymentMethod;
          if (data.paymentPlanMethod.enabled)
            data.modulesToShow[
              callbackSuccessData.data.modules.paymentMethod.index
            ] = "paymentPlanMethod";
          if (data.showDebtSelector === 0)
            data.showDebtSelector =
              callbackSuccessData.data.modules.welcomeMessage.index;

          data.support = callbackSuccessData.data.modules.support;
          store.commit("modules/setSupport", data.support);
          if (data.support.enabled)
            data.modulesToShow[callbackSuccessData.data.modules.support.index] =
              "support";
          if (data.showDebtSelector === 0)
            data.showDebtSelector =
              callbackSuccessData.data.modules.welcomeMessage.index;

          data.contactUs = callbackSuccessData.data.modules.contact;
          if (data.contactUs.enabled)
            data.modulesToShow[callbackSuccessData.data.modules.contact.index] =
              "contactUs";
          if (data.showDebtSelector === 0)
            data.showDebtSelector =
              callbackSuccessData.data.modules.welcomeMessage.index;

               data.whatsApp = callbackSuccessData.data.modules.whatsapp
             if (data.whatsApp.enabled)
            data.modulesToShow[callbackSuccessData.data.modules.whatsapp.index] =
              "whatsapp";

          data.actionsHistory = callbackSuccessData.data.modules.paymentRecords;
          if (data.actionsHistory.enabled)
            data.modulesToShow[
              callbackSuccessData.data.modules.paymentRecords.index
            ] = "actionsHistory";

          data.debtConsolidation =
            callbackSuccessData.data.modules.debtConsolidation;
          if (data.debtConsolidation.enabled)
            data.modulesToShow[
              callbackSuccessData.data.modules.debtConsolidation.index
            ] = "debtConsolidation";
          if (data.showDebtSelector === 0)
            data.showDebtSelector =
              callbackSuccessData.data.modules.welcomeMessage.index;

          data.files = callbackSuccessData.data.modules.files;
          if (data.files.enabled)
            data.modulesToShow[callbackSuccessData.data.modules.files.index] =
              "files";
          if (data.showDebtSelector === 0)
            data.showDebtSelector =
              callbackSuccessData.data.modules.welcomeMessage.index;

          data.faqs = callbackSuccessData.data.modules.faqs;
          if (data.faqs.enabled)
            data.modulesToShow[callbackSuccessData.data.modules.faqs.index] =
              "faqs";
          if (data.showDebtSelector === 0)
            data.showDebtSelector =
              callbackSuccessData.data.modules.welcomeMessage.index;

          data.whoIs = callbackSuccessData.data.modules.whoIs;
          if (data.whoIs.enabled)
            data.modulesToShow[callbackSuccessData.data.modules.whoIs.index] =
              "whoIs";

          data.footer = callbackSuccessData.data.modules.footer;
          if (data.footer.enabled)
            data.modulesToShow[callbackSuccessData.data.modules.footer.index] =
              "footer";
          store.commit(
            "modules/setFooter",
            callbackSuccessData.data.modules.footer
          );
      
          data.debtor = callbackSuccessData.data.modules.debtor;
          if (data.debtor.enabled)
            data.modulesToShow[callbackSuccessData.data.modules.debtor.index] =
              "debtor";
          if (data.showDebtSelector === 0)
            data.showDebtSelector =
              callbackSuccessData.data.modules.welcomeMessage.index;
          store.commit(
            "modules/setDebtModule",
            callbackSuccessData.data.modules.debtor
          );
          // data.removals = callbackSuccessData.data.modules.removals
          // if (data.removals.enabled) data.modulesToShow[callbackSuccessData.data.modules.removals.index] = 'removals'

          if (callbackSuccessData.data.modules.chatBot.enabled)
            data.modulesToShow[callbackSuccessData.data.modules.chatBot.index] =
              "chatBot";

          data.loading = false;
          redirectToViewBasedOnTemplate(props.uuid);
        },
        callbackError: (callbackErrorData: any) => {
          data.loading = false;
          if (callbackErrorData.data.text) {
            store.commit(
              "security/setFallbackContactInfoLogo",
              callbackErrorData.data.logo
            );
            store.commit(
              "security/setFallbackContactInfoAddress",
              callbackErrorData.data.address
            );
            store.commit(
              "security/setFallbackContactInfoPhone",
              callbackErrorData.data.phone
            );
            store.commit(
              "security/setFallbackContactInfoEmail",
              callbackErrorData.data.email
            );
            store.commit(
              "security/setFallbackContactInfoTextRu",
              callbackErrorData.data.text.ru
            );
            store.commit(
              "security/setFallbackContactInfoTextEs",
              callbackErrorData.data.text.es
            );
            store.commit(
              "security/setFallbackContactInfoTextEn",
              callbackErrorData.data.text.en
            );
            router.push({ name: "GoError403" });
          }
        },
      };
      store.dispatch("modules/getLandingConfiguration", params);
    }

    function getLandingAuth() {
      data.loadingAuth = true;
      const params = {
        uuid: props.uuid,
        callbackSuccess: (callbackSuccessData: any) => {
          if (callbackSuccessData.data.authMode === "default") {
            // postSendPin()
            data.authMode = callbackSuccessData.data.authMode;
          } else if (callbackSuccessData.data.authMode === "pin+uniqueId") {
            data.authMode = callbackSuccessData.data;
            data.phonesContact = callbackSuccessData.data.phones.map(
              (phone: any, index: number) => ({
                label: phone,
                value: index,
              })
            );
            data.emailsContact = callbackSuccessData.data.emails.map(
              (email: any, index: number) => ({
                label: email,
                value: index,
              })
            );
            data.sendMethod = callbackSuccessData.data.sendMethod;
          } else if (callbackSuccessData.data.authMode === "pin+uniqueId") {
            store.commit(
              "modules/setPhonesContacts",
              callbackSuccessData.data.phones.map(
                (phone: any, index: number) => ({
                  label: phone,
                  value: index,
                })
              )
            );
            store.commit(
              "modules/setEmailsContacts",
              callbackSuccessData.data.emails.map(
                (email: any, index: number) => ({
                  label: email,
                  value: index,
                })
              )
            );
            store.commit(
              "modules/setSendMethod",
              callbackSuccessData.data.sendMethod
            );
          } else if ("token" in callbackSuccessData.data) {
            store.commit("security/setToken", callbackSuccessData.data.token);
            data.pinAuthenticationIsFinished = true;
            getLandingConfiguration();
          }
          data.loadingAuth = false;
        },

        callbackError: (callbackErrorData: any) => {
          data.loadingAuth = false;
          if (callbackErrorData.data.text) {
            store.commit(
              "security/setFallbackContactInfoLogo",
              callbackErrorData.data.logo
            );
            store.commit(
              "security/setFallbackContactInfoAddress",
              callbackErrorData.data.address
            );
            store.commit(
              "security/setFallbackContactInfoPhone",
              callbackErrorData.data.phone
            );
            store.commit(
              "security/setFallbackContactInfoEmail",
              callbackErrorData.data.email
            );
            store.commit(
              "security/setFallbackContactInfoTextRu",
              callbackErrorData.data.text.ru
            );
            store.commit(
              "security/setFallbackContactInfoTextEs",
              callbackErrorData.data.text.es
            );
            store.commit(
              "security/setFallbackContactInfoTextEn",
              callbackErrorData.data.text.en
            );
            router.push({ name: "GoError403" });
          }
        },
      };
      // redirectToViewBasedOnTemplate(props.uuid);
      store.dispatch("modules/getLandingAuth", params);
    }

    function getGeneralModule() {
      const params = {
        uuid: props.uuid,
        callbackSuccess: (callbackSuccessData: any) => {
          store.commit(
            "security/setFallbackContactInfoAddress",
            callbackSuccessData.data.fallbackContactInfo.address
          );
          store.commit(
            "security/setFallbackContactInfoPhone",
            callbackSuccessData.data.fallbackContactInfo.phone
          );
          store.commit(
            "security/setFallbackContactInfoEmail",
            callbackSuccessData.data.fallbackContactInfo.email
          );
          store.commit(
            "security/setFallbackContactInfoTextRu",
            callbackSuccessData.data.fallbackContactInfo.text.ru
          );
          store.commit(
            "security/setFallbackContactInfoTextEs",
            callbackSuccessData.data.fallbackContactInfo.text.es
          );
          store.commit(
            "security/setFallbackContactInfoTextEn",
            callbackSuccessData.data.fallbackContactInfo.text.en
          );
          store.commit(
            "modules/setAuthMode",
            callbackSuccessData.data.authMode
          );
          store.commit(
            "modules/setCustomApi",
            callbackSuccessData.data.customApi
          );
          store.commit('modules/setPinPageCustomization', callbackSuccessData.data.pinPageCustomization)
          data.dynamicTemplate = callbackSuccessData.data.uxMode;
          data.pinContentHtml = callbackSuccessData.data.pinContentHtml;
          data.languages = callbackSuccessData.data.extraLanguage;
          data.pinPageCustomization =
            callbackSuccessData.data.pinPageCustomization;
          data.customApi = callbackSuccessData.data.customApi;
          data.authModeNew = callbackSuccessData.data.authMode;
          // redirectToViewBasedOnTemplate(props.uuid);
        },
        callbackError: () => {},
      };
      store.dispatch("modules/getGeneralModule", params);
    }

    function getLandingDocuments() {
      const params = {
        uuid: props.uuid,
        callbackSuccess: (callbackSuccessData: any) => {
          data.uploadDocuments = callbackSuccessData.data.documents;
        },
        callbackError: () => {},
      };
      store.dispatch("modules/getLandingDocuments", params);
    }

    function getFilesToAddToPayment() {
      const params = {
        uuid: "/api/landing_pages/" + props.uuid,
        callbackSuccess: (callbackSuccessData: any) => {
          if (
            callbackSuccessData.data.availableFilesToAddToAPaymentPlan.length >
            0
          ) {
            let filesInfoResult: Array<any> = [];
            for (let fileKey in callbackSuccessData.data
              .availableFilesToAddToAPaymentPlan) {
              filesInfoResult.push(
                callbackSuccessData.data.availableFilesToAddToAPaymentPlan[
                  fileKey
                ]
              );
            }
            if (data.paymentPlan.removalsPercentage > 0) {
              store.commit(
                "modules/setAmountWithRemoval",
                callbackSuccessData.data.totalAmountIncludingRemoval
              );
            } else {
              store.commit(
                "modules/setAmountWithRemoval",
                callbackSuccessData.data.totalAmountWithoutIncludingRemoval
              );
            }
            // store.commit('modules/setFilesInfo', filesInfoResult)
          }
        },
        callbackError: () => {},
      };
      store.dispatch("modules/getFilesToAddToPayment", params);
    }

    function goToContactUs() {
      let element = document.getElementById("contactUs");
      if (element !== null) element.scrollIntoView({ behavior: "smooth" });
    }

    function goTo(component: string) {
      let element = document.getElementById(component);
      if (element !== null) element.scrollIntoView({ behavior: "smooth" });
      data.currentSection = component;
    }

    function postSendPin() {
      const params = {
        uuid: props.uuid,
        callbackSuccess: () => {},
        callbackError: (callbackErrorData: any) => {},
      };
      store.dispatch("modules/postSendPin", params);
    }

    function successfulAuthentication() {
      data.pinAuthenticationIsFinished = true;
      getLandingConfiguration();
    }

    // function redirectToViewBasedOnTemplate(uuid: any) {
    //   if (data.dynamicTemplate === "template1")
    //     return router.push({ name: "axactor-view", params: uuid });
    //   else return router.push({ name: "Home", params: uuid });
    // }

    getGeneralModule();

    const scollToPaymentModule = () => {
      let element: any | null = document.getElementById("PlanModule");
      element.scrollIntoView({ block: "start" });
    };

    onMounted(() => {
      const { query } = router.currentRoute.value;

      if (query && query.state && query.deadline && query.landing) {
        data.query.state = query.state;
        data.query.deadline = query.deadline;
        data.query.landing = query.landing;
      }

      if (query && query.state && query.amount && query.file) {
        data.query.state = query.state;
        data.query.amount = query.amount;
        data.query.file = query.file;
      }

      if (data.query.state === "ok") {
        postPaymentStripe();
        data.showPaymentStripeModal = true;
      }

      if (data.query.state === "ko") {
        // store.commit('user/pushNotification', {
        //   id: Date.now(),
        //   icon: '',
        //   message: 'notifications.paymentError',
        //   color: 'danger'
        // })
        data.showPaymentStripeModal = true;
      }
    });

    onMounted(() => {
      const { query } = router.currentRoute.value;
      if (query && query.check) {
          data.checkPaymentWorldLine = query.check
          data.showPaymentModal = true
      }
    });

    // watch(() => store.getters['modules/getFileUuid'],() => {
    //   const { query } = router.currentRoute.value;
    //   if (query && query.Ds_SignatureVersion && query.Ds_MerchantParameters && query.Ds_Signature) {getPaymentPlanData()}
    // })

    // function getPaymentPlanData() {
    //   const params = {
    //     uuid: '/api/landing_pages/' + props.uuid,
    //     callbackSuccess: (callbackSuccessData: any) => {
    //       callbackSuccessData.data.forEach((item: any)  => {
    //           if(item.status === "approved") {
    //             data.variablesForVerifyPayment.deadLineId = item.paymentPlanDeadline['@id']
    //             data.variablesForVerifyPayment.fileId = '/api/files/' + store.getters['modules/getFileUuid']
    //           }
    //         })
    //       postPaymentVerify()
    //     },
    //     callbackError: () => {},
    //   };
    //   store.dispatch("modules/getPaymentPlanData", params);
    // }

    function closePaymentModal() {
      data.showPaymentModal = false;
      let cleanUrl = window.location.href.split("?check")[0];
      window.history.replaceState({}, document.title, cleanUrl);
      window.onpopstate = function () {
        window.history.go(1);
      };
    }

    function closeStripModal() {
      data.showPaymentStripeModal = false;
      let cleanUrl = window.location.href.split("?state")[0];
      window.history.replaceState({}, document.title, cleanUrl);
      window.onpopstate = function () {
        window.history.go(1);
      };
    }

    function postPaymentStripe() {
      let params = {};
      if (data.query.deadline.length > 0) {
        params = {
          uuid: "/api/landing_pages/" + props.uuid,
          deadline: data.query.deadline,
          callbackSuccess: (callbackSuccessData: any) => {
            data.query.deadline = "";
          },
          callbackError: () => {
            data.query.deadline = "";
          },
        };
      } else {
        params = {
          uuid: "/api/landing_pages/" + props.uuid,
          amount: data.query.amount,
          file: data.query.file,
          callbackSuccess: (callbackSuccessData: any) => {
            data.query.deadline = "";
          },
          callbackError: () => {
            data.query.deadline = "";
          },
        };
      }
      store.dispatch("modules/postPaymentStripe", params);
    }

    const language = computed(() => {
      return store.getters["user/getLanguage"];
    });

    watch(language, () => {
      data.callMeNowKey++;
    });
    function redirectToViewBasedOnTemplate(uuid: any) {
      if (data.dynamicTemplate === "template1") {
        if (data.authModeNew === "noAuth" && isMobile.value) {
          return router.push({ name: "DebtsView", params: uuid });
        } else if (data.authModeNew !== "noAuth" && isMobile.value) {
          return router.push({ name: "Authentication", params: uuid });
        } else if (!isMobile.value) {
          return router.push({ name: "AxactorView", params: uuid });
        }
      }
    }

    watch(language, () => {
      data.callMeNowKey++;
    });

     watch(language, () => {
      data.whatsAppKey++;
    });

    getLandingAuth();
    store.commit("modules/setTemplateId", props.template);
    store.commit("modules/setElementId", props.element);

    return {
      ...toRefs(data),
      changeSelectedMethod,
      changeSelectedPayment,
      filesInfo,
      getLandingConfiguration,
      getLandingDocuments,
      goToContactUs,
      goTo,
      scollToPaymentModule,
      successfulAuthentication,
      isMobile,
      postPaymentStripe,
      closeStripModal,
      language,
      goToTemplates,
      // redirectToViewBasedOnTemplate,
      redirectToViewBasedOnTemplate,
      closePaymentModal
    };
  },
});
